import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/services/auth.service";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Inicio",
    type: "link",
    icontype: "tim-icons icon-chart-pie-36",
    rtlTitle: "Inicio"
  },
  {
    path: "/admin",
    title: "Administración",
    type: "sub",
    icontype: "tim-icons icon-settings-gear-63",
    collapse: "pages",
    rtlTitle: "1",
    isCollapsed: true,
    children: [
      {
        path: "unidades",
        rtlTitle: "دعم رتل ",
        rtlSmallTitle: " ت",
        title: "Unidades",
        type: "link",
        smallTitle: "UI"
      },
      {
        path: "roles",
        rtlTitle: "دعم رتل ",
        rtlSmallTitle: "ص",
        title: "Roles",
        type: "link",
        smallTitle: "R"
      },
      {
        path: "permisos",
        rtlTitle: "دعمرتل ",
        rtlSmallTitle: "ص",
        title: "Permisos",
        type: "link",
        smallTitle: "P"
      },
      {
        path: "usuarios",
        rtlTitle: " التسعير ",
        rtlSmallTitle: "ع ",
        title: "Usuarios",
        type: "link",
        smallTitle: "U"
      },
      {
        path: "subgrupos",
        rtlTitle: "دعمرتل ",
        rtlSmallTitle: "ص",
        title: "Subgrupos",
        type: "link",
        smallTitle: "SG"
      },
      {
        path: "predios",
        rtlTitle: "دعمرتل ",
        rtlSmallTitle: "ص",
        title: "Predios",
        type: "link",
        smallTitle: "P"
      },
      {
        path: "ufv",
        rtlTitle: "دعمرتل ",
        rtlSmallTitle: "ص",
        title: "UFV",
        type: "link",
        smallTitle: "U"
      }
    ]
  },
  {
    path: "/activos",
    title: "Registro de activos",
    type: "link",
    icontype: "tim-icons icon-app",
    collapse: "pages",
    rtlTitle: "2"
  },
  {
    path: "/contabilidad",
    title: "Actualizaciones y depreciaciones",
    type: "link",
    icontype: "tim-icons icon-chart-bar-32",
    rtlTitle: "3"
  },
  {
    path: "/movimientos",
    title: "Movimientos de activos",
    type: "link",
    icontype: "tim-icons icon-refresh-02",
    rtlTitle: "4"
  },
  {
    path: "/report",
    title: "Reportes",
    type: "link",
    icontype: "tim-icons icon-notes",
    rtlTitle: "5"
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  data: any[];
  constructor(private authService: AuthService) { }

  ngOnInit() {
    let rolArray = [];
    const role = this.authService.user.permisos;    
    for (var i = 0; i < role.length; i++) {
      rolArray.push(ROUTES.find(obj => obj.path === '/' + role[i]['nombre']))
    }

    rolArray.sort((a, b) => (a.rtlTitle > b.rtlTitle) ? 1 : -1);
    this.menuItems = rolArray;
  }

  home() {
    window.open("/", "_self");
  }
}
