import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AsignacionPersistDto } from 'src/app/shared/models/movimientos/asignacion-persist-dto';

@Injectable({
  providedIn: 'root'
})
export class AsignacionesService {

  constructor(private http: HttpClient) { }
  
  persist(asignacionPersistDto: AsignacionPersistDto): Observable<any> {
    return this.http.post(`${environment.apiSecurityUrl}api/v1/asignaciones`, asignacionPersistDto);
  }

  getEmisores(): Observable<any> {  
    return this.http.get(`${environment.apiSecurityUrl}api/v1/usuarios`);
  }

  getActaAsignacion(idAsignacion: number, idUsuarioActual: number): void {
    window.open(`${environment.apiReportsUrl}api/v1/reports/asignaciones/${idAsignacion}/${idUsuarioActual}`, '_blank');
  }  
}
