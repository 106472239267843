import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { EstadoRedService } from "./shared/services/estado-red/estado-red.service";
import { OfflineSharedService } from "./shared/services/offline-shared/offline-shared.service";
import { OfflineService } from "./core/services/offline/offline.service";
import { AsignacionesService } from "./core/services/asignaciones/asignaciones.service";
import { AsignacionPersistDto } from "./shared/models/movimientos/asignacion-persist-dto";
import { UsuarioService } from "./core/services/usuario.service";
import { ParametrosService } from "./core/services/parametros/parametros.service";
import { MovimientosService } from "./core/services/movimientos/movimientos.service";
import { AuthService } from "./core/services/auth.service";
import { ActivosService } from "./core/services/activos/activos.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  idUsuarioActual: number;
  estadoRed: boolean;
  registrosOffline: any[] = [];

  constructor(
    private estadoRedService: EstadoRedService,
    public toastr: ToastrService,
    private offlineService: OfflineService,
    private offlineSharedService: OfflineSharedService,
    private usuarioService: UsuarioService,
    private parametrosService: ParametrosService,
    private asignacionesService: AsignacionesService,
    private movimientosService: MovimientosService,
    private authService: AuthService,
    private activosService: ActivosService
  ){
    this.estadoRed = true;
    this.idUsuarioActual = this.authService.user.id;
  }

  ngOnInit(): void {
    this.getAccionesLocales();

    this.offlineSharedService.getActualizarPanelAccionesLocales().subscribe((response) => {
      if (response) {
        this.getAccionesLocales();
        this.offlineSharedService.setActualizarPanelAccionesLocales(false);
      }
    });
  }

  cambiarFuncionamiento(): void {
    this.estadoRed = !this.estadoRed;

    if (this.estadoRed) {
      this.estadoRedService.setNetworkStatus(true);
      this.estadoRed = true;
    } else {
      this.estadoRedService.setNetworkStatus(false);
      this.estadoRed = false;
    }
  }

  getAccionesLocales(): void {
    this.offlineService.findAll().then(response => {
      this.registrosOffline = response;
    }).catch(err => {
      this.toastr.error(err.message);
    });
  }

  deleteAccionLocal(accionId: number): void {
    this.offlineService.delete(accionId).then()
    .catch(err => {
      this.toastr.error(err.message);
    });
  }

  auxSyncAcciones(registro: any): any {
    return new Promise((resolve, reject) => {
      if (registro.tipoAccion === 'asignacion') {
        let dto = new AsignacionPersistDto;
        dto = JSON.parse(registro.cuerpoAccion);

        this.asignacionesService.persist(dto).subscribe((data) => {
          if (data.status) {
            this.toastr.success(data.message);
            this.toastr.success("Generando acta de asignación...");
            let idAsignacion = data.data;
            this.asignacionesService.getActaAsignacion(idAsignacion, this.idUsuarioActual);
            resolve(true);
          } else {
            this.toastr.error('Ocurrio un error: ' + data.message);
            reject(false);
          }
        },
        error => {
          this.toastr.error('Ocurrio un error: ' + error.message);
          reject(false);
        });
      } else if (registro.tipoAccion === 'ingreso-salida') {
        let dto = JSON.parse(registro.cuerpoAccion);
        this.movimientosService.persistActaIngresoSalida(dto).subscribe(
          (data) => {
            if (data.status) {
              this.toastr.success("Generando acta de ingreso/salida...");
              let permisoIngresoSalida = data.data;
              this.movimientosService.getActaIngresoSalida(permisoIngresoSalida, this.idUsuarioActual);
              resolve(true);
            } else {
              this.toastr.error("Ocurrio un error: " + data.message);
              reject(false);
            }
          }
        );
      } else if (registro.tipoAccion === 'baja-activo') {
        let dto = JSON.parse(registro.cuerpoAccion);
        let idTemp = registro.idAccion;

        this.activosService.delete(idTemp, dto).subscribe(
          (data) => {
            if (data.status) {
              this.toastr.success("Se realizo la baja del activo exitosamente.");
              resolve(true);
            } else {
              this.toastr.error("Ocurrio un error: " + data.message);
              reject(false);
            }
          },
          (error) => {
            this.toastr.error("Ocurrio un error: " + error.message);
            reject(false);
          }
        );
      }
    });
  }

  async sync() {
    for (let registro of this.registrosOffline) {
      const response = await this.auxSyncAcciones(registro);      
      if (response === true) {
        this.deleteAccionLocal(registro.id);
      }
    }

    this.getAccionesLocales();
    window.location.reload();
  }
}
