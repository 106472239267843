import { Injectable } from '@angular/core';
import { AccionOfflinePersistDto } from 'src/app/shared/models/acciones-offline/accion-offline-persist-dto';
declare var offlineDatabase: any;

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  public storeAcciones: string;
  public storeUsuarios: string;
  public storeTiposAsignacion: string;
  public storeEmisores: string;

  constructor() {
    this.storeAcciones = 'acciones';
    this.storeUsuarios = 'usuarios';
    this.storeTiposAsignacion = 'tiposAsignacion';
    this.storeEmisores = 'emisores';
  }

  // Servicios para acciones
  findAll(): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = await offlineDatabase.result
                      .transaction([this.storeAcciones], 'readonly')
                      .objectStore(this.storeAcciones)
                      .getAll();

        request.onsuccess = await function(event) {
          const acciones = event.target.result;
          resolve(acciones);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al obtener acciones locales: ${err}`));
        }
      }
    })
  }

  persist(id: number, accionOffline: AccionOfflinePersistDto): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = offlineDatabase.result
                      .transaction([this.storeAcciones], 'readwrite')
                      .objectStore(this.storeAcciones)
                      .put({
                        tipoAccion: accionOffline.tipoAccion,
                        idAccion: id,
                        cuerpoAccion: accionOffline.cuerpoAccion
                      });

        request.onsuccess = await function() {
          const guardadoCorrecto = request.result;
          resolve(guardadoCorrecto);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al guardar acción localmente: ${err}`));
        }
      }
    })
  }

  delete(accionOfflineId: number): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = offlineDatabase.result
                      .transaction([this.storeAcciones], 'readwrite')
                      .objectStore(this.storeAcciones)
                      .delete(accionOfflineId);

        request.onsuccess = await function() {
          resolve(true);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al eliminar acción local: ${err}`));
        }
      }
    })
  }


  // Servicios para usuarios
  findUsuarios(): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = await offlineDatabase.result
                      .transaction([this.storeUsuarios], 'readonly')
                      .objectStore(this.storeUsuarios)
                      .get(1);

        request.onsuccess = await function(event) {
          const acciones = event.target.result;
          resolve(acciones);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al obtener usuarios locales: ${err}`));
        }
      }
    })
  }

  persistUsuarios(usuarios: any): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = offlineDatabase.result
                      .transaction([this.storeUsuarios], 'readwrite')
                      .objectStore(this.storeUsuarios)
                      .put({
                        arrayUsuarios: usuarios
                      });

        request.onsuccess = await function() {
          const guardadoCorrecto = request.result;
          resolve(guardadoCorrecto);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al guardar usuarios localmente: ${err}`));
        }
      }
    })
  }

  // Servicios para tipos de asignaciones
  findAllTiposAsignaciones(): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = await offlineDatabase.result
                      .transaction([this.storeTiposAsignacion], 'readonly')
                      .objectStore(this.storeTiposAsignacion)
                      .get(1);

        request.onsuccess = await function(event) {
          const acciones = event.target.result;
          resolve(acciones);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al obtener tipos de asignaciones locales: ${err}`));
        }
      }
    });
  }

  persistTiposAsignaciones(tiposAsignacion: any): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = offlineDatabase.result
                      .transaction([this.storeTiposAsignacion], 'readwrite')
                      .objectStore(this.storeTiposAsignacion)
                      .put({
                        arrayTiposAsignacion: tiposAsignacion
                      });

        request.onsuccess = await function() {
          const guardadoCorrecto = request.result;
          resolve(guardadoCorrecto);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al guardar tipos de asignación localmente: ${err}`));
        }
      }
    })
  }

  // Servicios para emisores (para ordenes de ingreso/salida)
  findAllEmisores(): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = await offlineDatabase.result
                      .transaction([this.storeEmisores], 'readonly')
                      .objectStore(this.storeEmisores)
                      .get(1);

        request.onsuccess = await function(event) {
          const acciones = event.target.result;
          resolve(acciones);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al obtener emisores locales: ${err}`));
        }
      }
    })
  }

  persistEmisoresOrdenesIngresoSalida(emisores: any): any {
    return new Promise(async(resolve, reject) => {
      if (offlineDatabase != undefined) {
        const request = offlineDatabase.result
                      .transaction([this.storeEmisores], 'readwrite')
                      .objectStore(this.storeEmisores)
                      .put({
                        arrayEmisores: emisores
                      });

        request.onsuccess = await function() {
          const guardadoCorrecto = request.result;
          resolve(guardadoCorrecto);
        }

        request.onerror = (err) => {
          reject(new Error(`Error al guardar emisores localmente: ${err}`));
        }
      }
    })
  }
}
