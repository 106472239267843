import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import jsQR from "jsqr";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-escaner',
  templateUrl: './escaner.component.html',
  styleUrls: ['./escaner.component.scss']
})
export class EscanerComponent implements OnInit {
  title: string;
  video = document.createElement("video");
  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;
  mensajeCarga: string;
  datosActivo: any;
  lecturaActivada: boolean;
  stream: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EscanerComponent>,
    public toastr: ToastrService
  ) {
    this.title = 'Escanear QR';
    this.mensajeCarga = 'Permite el uso de tu cámara';
    this.datosActivo = {};
    this.lecturaActivada = true;
  }

  ngAfterViewInit() {
    this.ctx = this.canvas.nativeElement.getContext('2d');
  }

  ngOnInit(): void {
    // facingMode: para intentar usar la camara frontal del telefono
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
    .then((stream) => {
      this.video.srcObject = stream;
      this.video.play();
      this.tick();
    });
  }

  drawLine(begin, end, color) {
    this.ctx.beginPath();
    this.ctx.moveTo(begin.x, begin.y);
    this.ctx.lineTo(end.x, end.y);
    this.ctx.lineWidth = 4;
    this.ctx.strokeStyle = color;
    this.ctx.stroke();
  }

  tick() {
    this.mensajeCarga = 'Cargando video...';
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA && this.lecturaActivada === true) {
      this.mensajeCarga = '';
      this.canvas.nativeElement.hidden = false;

      this.canvas.nativeElement.height = this.video.videoHeight;
      this.canvas.nativeElement.width = this.video.videoWidth;
      this.ctx.drawImage(this.video, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      var imageData = this.ctx.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
      
      var code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });

      if (code) {
        this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
        this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
        this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
        this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

        this.lecturaActivada = false; //Pausar la lectura
        
        this.verificarDatos(code.data);
      }
    }
    window.requestAnimationFrame(() => this.tick());
  }

  verificarDatos(datos: string): void {    
    let arrayDatos = datos.split("|");

    let datosActivo = {
      id: parseInt(arrayDatos[0]),
      grupo: arrayDatos[1],
      subgrupo: arrayDatos[2],
      descripcion: arrayDatos[3],
      codigoActivo: arrayDatos[4],
      codigoHistorico: arrayDatos[5],
      fechaIncorporacion: arrayDatos[6],
      costo: parseFloat(arrayDatos[7]).toFixed(2),
      idEstadoActivo: parseInt(arrayDatos[8]),
      estadoActivo: arrayDatos[9],
      aprobado: (arrayDatos[10] === 'true'),
      createdBy: arrayDatos[11],
      createdDate: arrayDatos[12]
    }
    
    if (typeof datosActivo.id == 'number' && arrayDatos.length === 14) { //Validaciones para el codigo QR
      console.log("Válido");
      this.dialogRef.close(datosActivo);
    } else {
      this.toastr.error("Código QR no válido");
      setTimeout(() => {
        this.lecturaActivada = true;
      }, 1500);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
