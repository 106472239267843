import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserAuth } from 'src/app/shared/models/user-auth.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _user: UserAuth;
    private _token: string;

    constructor(private http: HttpClient) {
        console.log(environment.apiSecurityUrl);
    }

    public get user(): UserAuth {
        if (this._user != null) {
            return this._user;
        } else if (this._user == null && sessionStorage.getItem('user') != null) {
            this._user = JSON.parse(sessionStorage.getItem('user')) as UserAuth;
            return this._user;
        }
        return new UserAuth();
    }

    public get token(): string {
        if (this._token != null) {
            return this._token;
        } else if (this._token == null && sessionStorage.getItem('token') != null) {
            this._token = sessionStorage.getItem('token');
            return this._token;
        }
        return null;
    }

    login(user: UserAuth): Observable<any> {
        const credenciales = btoa('angularapp' + ':' + '12345');
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + credenciales
        });
        let params = new URLSearchParams();
        params.set('grant_type', 'password');
        params.set('username', user.username);
        params.set('password', user.password);
        return this.http.post<any>(environment.apiSecurityUrl + 'oauth/token', params.toString(), { headers: httpHeaders });
    }

    saveUser(accessToken: string): void {
        let payload = this.getDataToken(accessToken);
        this._user = new UserAuth();
        this._user.id = payload.id;
        this._user.nombreCompleto = payload.nombreCompleto;
        this._user.email = payload.email;
        this._user.username = payload.username;
        this._user.roles = payload.authorities;
        this._user.permisos = payload.permisos;
        this._user.idUnidad = payload.idUnidad;
        sessionStorage.setItem('user', JSON.stringify(this._user));
    }
    saveToken(accessToken: string): void {
        this._token = accessToken;
        sessionStorage.setItem('token', accessToken);
    }

    getDataToken(accessToken: string): any {
        if (accessToken != null) {
            return JSON.parse(atob(accessToken.split(".")[1]));
        }
        return null;
    }

    isAuthenticated(): boolean {
        let payload = this.getDataToken(this.token);
        if (payload != null && payload.user_name && payload.user_name.length > 0) {
            return true;
        }
        return false;
    }

    hasRole(role: string): boolean {
        var index = 0;
        for (let i = 0; i < this.user.permisos.length; i++) {
            if (this.user.permisos[i]['nombre'] === role[0]) {
                index++;
            }
        }
        if (index > 0)
            return true;
        return false;
    }

    logout(): void {
        this._user = null;
        this._token = null;
        sessionStorage.clear();
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
    }
}