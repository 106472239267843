import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr';
import { OperacionEnum } from 'src/app/core/enums/operacion.enum';
import { PermisoService } from 'src/app/core/services/admin/permiso.service';
import { PermisoDto } from 'src/app/shared/models/admin/permiso-dto.model';
import { PermisoVo } from 'src/app/shared/models/admin/permiso-vo.model';
import { RolVo } from 'src/app/shared/models/rol-vo.model';
import { ComponentsModule } from 'src/app/components/components.module';

@Component({
  selector: 'app-usuario-grid',
  templateUrl: './usuario-grid.component.html',
  styleUrls: ['./usuario-grid.component.scss']
})
export class UsuarioGridComponent implements OnInit {

  form: FormGroup;
  public operation: OperacionEnum;
  permisoVo: PermisoVo;
  dto: PermisoDto;
  title: string;
  button: string;

  dataSource;
  public displayedColumns: string[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  selection = new SelectionModel<RolVo>(true, []);
  searchKey: string;
  
  public usuarios: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UsuarioGridComponent>,
    private permisoService: PermisoService,
    private notifierService: ToastrService,
    private formBuilder: FormBuilder) { 
      this.form = this.formBuilder.group({
        id: [''],
        codigo: ['', Validators.required],
        nombre: ['', Validators.required],
        createdBy: [''],
        createdDate: ['']
      });
      this.data = data;
      this.operation = data.operation;
      this.permisoVo = data.entity;
      this.title = data.title;
      this.button = data.button;
  }

  ngOnInit(): void {
    switch (this.operation) {
      case OperacionEnum.create:
        this.form.enable();
        break;
      case OperacionEnum.update:
        this.form.setValue(this.permisoVo);
        this.form.enable();
        break;
    }

    this.loadList();
  }

  loadList(): void {
    this.displayedColumns = ['nombre', 'nombreCompleto', 'unidad', 'actions'];
    this.dataSource = new MatTableDataSource<any>(this.data.usuarios);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  save() {
    if (this.form.get('codigo')?.invalid)
      this.notifierService.warning('Debe introducir el codigo');
    if (this.form.get('nombre')?.invalid)
      this.notifierService.warning('Debe introducir el nombre');
    else {
      if (this.form.invalid) {
        return;
      }
      this.dto = new PermisoDto(this.form.value.codigo, this.form.value.nombre);
      switch (this.operation) {
        case OperacionEnum.create:
          this.permisoService.persist(this.dto).subscribe(
            data => {
              if (data.status) {
                this.notifierService.success("Se registro el permiso exitosamente.");
                this.dialogRef.close(true);
              } else {
                this.notifierService.error('Ocurrio un error: ' + data.message);
              }
            },
            error => {
              this.notifierService.error('Ocurrio un error: ' + error.message);
            }
          );
          break;
        case OperacionEnum.update:
          this.permisoService.update(this.permisoVo.id, this.dto).subscribe(
            data => {
              if (data.status) {
                this.notifierService.success("Actualizado correctamente.");
                this.dialogRef.close(true);
              } else {
                this.notifierService.warning('No se pudo realizar: ' + data.message);
              }
            },
            error => {
              this.notifierService.error('Ocurrió un error: ' + error.message);
            }
          );
          break;
      }
    }
  }

  selectUsuario(usuarioSeleccionado: any): void {
    this.dialogRef.close(usuarioSeleccionado);
  }
}