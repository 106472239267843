import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstadoRedService {
  private networkStatus: BehaviorSubject<boolean>;

  constructor() {
    this.networkStatus = new BehaviorSubject<boolean>(true);
  }

  getNetworkStatus(): Observable<boolean> {
    return this.networkStatus.asObservable();
  }

  setNetworkStatus(status): void {
    this.networkStatus.next(status);
  }
}
