import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Result } from 'src/app/shared/models/result';
import { environment } from 'src/environments/environment';
import { ActivoPersistDto } from 'src/app/shared/models/activos/activo-persist-dto';
import { ActivoUpdateDto } from 'src/app/shared/models/activos//activo-update-dto';

@Injectable({
  providedIn: 'root'
})
export class ActivosService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos`);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/${id}`);
  }

  getQR(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/` + id + `/generate-qr`, {headers, responseType: 'blob' as 'json'});
  }
  
  persist(activoPersistDto: any): Observable<any> {   
    return this.http.post(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos`, activoPersistDto);
  }

  updateActivo(idActivo: number, dto: ActivoUpdateDto): Observable<Result> {
    return this.http.put<Result>(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/${idActivo}`, dto);
  }

  updateAlta(activoId: number): Observable<any> {
    return this.http.put(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/${activoId}/aprobado`, null);
  }

  getGarantia(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/` + id + `/certificado/download`, {headers, responseType: 'blob' as 'json'});
  }

  getFactura(id: number): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/` + id + `/factura/download`, {headers, responseType: 'blob' as 'json'});
  }

  delete(id: number, objectoEliminacion: any): Observable<any> {    
    return this.http.request<any>('DELETE',`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/${id}`,{ body: objectoEliminacion });
  }

  findAllReporteConFiltros(parametros:string): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/filters?${parametros}`);
    
  }

  getActaAdquisicion(idAquisicion: number, idUsuarioActual: number): void {
    window.open(`${environment.apiReportsUrl}api/v1/reports/adquisiciones/${idAquisicion}/${idUsuarioActual}`, '_blank');
  }

  getNextId(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/adquisiciones/activos/nextid`);
  }

}