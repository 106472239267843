import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  constructor(private http: HttpClient) { }

  findAllDepartamentos(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/departamentos`);
  }

  findAllFuentes(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/activos/fuentes`);
  }

  findAllGrupos(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/grupos`);
  }

  findAllTipos(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/activos/tipos`);
  }

  getSubgruposByGrupo(idGrupo: number): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/grupos/${ idGrupo }/subgrupos`);
  }

  getDepartamento(): Observable<any> {  
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/departamentos`);
  }

  getEstados(): Observable<any> {  
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/activos/estados`);
  }

  getMotivosEliminacion(): Observable<any> {  
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/activos/motivoseliminacion`);
  }

  getTipos(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/parametros/activos/tiposasignaciones`);
  }
}
