import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ToastContainerModule,ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { TokenInterceptor } from "./core/interceptors/token.interceptor";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { HttpCallInterceptor } from "./core/interceptors/http.interceptor";
import { ConfirmDialogComponent } from "./shared/components/confirm-dialog/confirm-dialog.component";
import { UsuarioGridComponent } from "./modules/admin/usuario/usuario-grid/usuario-grid.component";
import { EscanerComponent } from './shared/components/escaner/escaner.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    RtlLayoutComponent,
    ConfirmDialogComponent,
    UsuarioGridComponent,
    EscanerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ComponentsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: "increasing",
      closeButton: true
    }),
    ToastContainerModule,
    MatTableModule,
    MatPaginatorModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpCallInterceptor, multi: true }],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmDialogComponent]
})
export class AppModule { }
