import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Result } from 'src/app/shared/models/result';
import { PermisoDto } from 'src/app/shared/models/admin/permiso-dto.model';

@Injectable({
  providedIn: 'root'
})

export class PermisoService {

  constructor(private http: HttpClient) { }

  findAll(): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/permisos`);
  }

  get(id: number): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/permisos/${id}`);
  }

  getId(id: number): Observable<any> {
    return this.http.get(`${environment.apiSecurityUrl}api/v1/permisos`);
  }

  persist(dto: PermisoDto): Observable<Result> {
    return this.http.post<Result>(`${environment.apiSecurityUrl}api/v1/permisos`, dto)
  }

  update(id: number, dto: PermisoDto): Observable<Result> {
    return this.http.put<Result>(`${environment.apiSecurityUrl}api/v1/permisos/${id}`, dto);
  }

  delete(id: number): Observable<Result> {
    return this.http.delete<Result>(`${environment.apiSecurityUrl}api/v1/permisos/${id}`);
  }

}
